function ajax(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onload = function () {
        if (xhr.status === 200) {
            callback(xhr.responseText);
        }
    };
    xhr.send();
}

function getMarkers(callback) {
    ajax('/gigs/markers', function (json) {
        callback(JSON.parse(json));
    });
}

// eslint-disable-next-line no-unused-vars
function initMap() {
    getMarkers(function (markers) {
        var lati = 0.0,
            longi = 0.0,
            count = markers.length;

        for (let i = 0; i < count; ++i) {
            lati += Number.parseFloat(markers[i].latitude);
            longi += Number.parseFloat(markers[i].longitude);
        }

        var center = {
            lat: lati / count,
            lng: longi / count,
        };

        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: center,
        });

        for (let i = 0; i < count; ++i) {
            addMarker(markers[i], map);
        }
    });
}

function addMarker(marker, map) {
    var mm = new google.maps.Marker({
        position: {
            lat: Number.parseFloat(marker.latitude),
            lng: Number.parseFloat(marker.longitude),
        },
        map: map,
        title: marker.title,
    });

    mm.addListener('click', function () {
        showGig(marker);
    });
}

function showGig(marker) {
    var ctr = document.getElementById('gig');

    if (ctr) {
        ajax('/gigs/' + marker.gig_slug + '/bare', function (html) {
            ctr.innerHTML = html;
        });
    }
}

const selector = document.getElementById('gig_venue_id');

if (selector) {
    selector.required = true;

    const searchBox = document.createElement('input');
    searchBox.type = 'search';
    searchBox.id = 'venuesearch';
    const label = document.createElement('label');
    label.innerHTML = 'Search Venues: ';
    label.for = 'venuesearch';
    let lastChoice = '';

    const ctr = selector.parentElement;

    ctr.insertBefore(searchBox, ctr.firstElementChild);
    ctr.insertBefore(label, searchBox);

    let pending = null;

    const fillUp = function (venues) {
        let opts = '';

        for (let venue of venues) {
            opts += `<option value="${venue.id}">${venue.name.replace(
                /</,
                '&lt;'
            )}</option>`;
        }

        selector.innerHTML = opts;
    };

    const handler = function () {
        const searchText = searchBox.value;

        if (searchText == lastChoice) {
            return;
        }

        if (pending) {
            clearTimeout(pending);
            pending = null;
        }

        pending = setTimeout(() => {
            pending = null;

            lastChoice = searchText;
            let url = `/venues/search/${encodeURIComponent(searchText)}`;

            if (!searchText) {
                url = '/venues/list_all';
            }

            var oReq = new XMLHttpRequest();
            oReq.addEventListener('load', function () {
                fillUp(JSON.parse(this.responseText));
            });
            oReq.open('GET', url);
            oReq.send();
        }, 500);
    };

    searchBox.addEventListener('change', handler);
    searchBox.addEventListener('blur', handler);
    searchBox.addEventListener('input', handler);
}

function liveQuery(selector, eventType, callback, context) {
    (context || document).addEventListener(
        eventType,
        function (event) {
            var nodeList = document.querySelectorAll(selector);

            var matches = [];
            for (var i = 0; i < nodeList.length; ++i) {
                matches.push(nodeList[i]);
            }

            if (matches) {
                var element = event.target;
                var index = -1;

                while (element && (index = matches.indexOf(element) === -1)) {
                    element = element.parentElement;
                }

                if (index > -1) {
                    callback.call(element, event);
                }
            }
        },
        false
    );
}

function calendarHandlers() {
    liveQuery('.calnav', 'click', (e) => {
        const element = e.target;
        const month = +element.getAttribute('data-month'),
            year = +element.getAttribute('data-year');

        if (month && year) {
            e.stopPropagation();
            e.preventDefault();

            const url = `${element.getAttribute(
                'data-url'
            )}?year=${year}&month=${month}`;

            ajax(url, (content) => {
                let parent = element.parentElement;

                while (parent && parent.tagName.toLowerCase() != 'table') {
                    parent = parent.parentElement;
                }

                if (parent) {
                    parent.outerHTML = content;
                }
            });
        }
    });
}

function selectorWidths() {
    const ourSelects = document.querySelectorAll('select.artist');
    let maxWidth = 0;

    for (let i = 0; i < ourSelects.length; ++i) {
        maxWidth = Math.max(maxWidth, ourSelects[i].clientWidth);
    }

    for (let i = 0; i < ourSelects.length; ++i) {
        ourSelects[i].style.width = `${maxWidth}px`;
    }
}

function selects() {
    selectorWidths();
}

function faveCheckBox() {
    const cb = document.getElementById('favorites_only');

    if (cb && cb.form) {
        const subber = cb.form.querySelector('input[type=submit]');

        if (subber) {
            subber.remove();

            cb.addEventListener('change', () => {
                cb.form.submit();
            });
        }
    }
}

function calendarSwitcher() {
    const linker = document.querySelector('.callink');
    const links = document.querySelector('.calendarlinks');

    if (linker && links) {
        linker.addEventListener('click', () => {
            linker.style.display = 'none';
            links.style.display = 'flex';
        });

        linker.className += ' calendarlinker';
        links.style.display = 'none';
    }
}

function gigTimes() {
    const starttime = document.getElementById('gig_time');
    const endtime = document.getElementById('gig_end_time');

    if (starttime && endtime) {
        endtime.addEventListener('blur', () => {
            if (endtime.value) {
                starttime.required = true;
            } else if (starttime.required) {
                starttime.required = false;
            }
        });

        starttime.addEventListener('blur', () => {
            if (starttime.value) {
                endtime.min = starttime.value;
            }
        });
    }
}

function setupMarkdown() {
    const ta = document.querySelector('textarea#gig_description');

    if (ta) {
        const woofmark = require('./woofmark');

        if (woofmark) {
            const editor = woofmark(ta, {
                parseMarkdown: require('./megamark'),
                parseHTML: require('./domador'),
                markdown: true,
                html: false,
                wysiwyg: true,
                defaultMode: 'wysiwyg',
            });

            const imf = document.getElementById('gig_is_markdown');

            if (imf) {
                imf.value = 'true';
            }

            const theForm = ta.form;

            if (theForm && imf) {
                theForm.addEventListener('submit', () => {
                    ta.value = editor.value();
                });
            }
        }
    }
}

calendarHandlers();
selects();
faveCheckBox();
calendarSwitcher();
gigTimes();
setupMarkdown();
